
.App {
  /* padding-top: 10px; */
  /* padding-right: 50px; */
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.menu {
  width: 90%;
  display: flex;
  flex-direction: row;
  color: #AA3D63;
  justify-content: right;
  align-items: center;
  text-transform: uppercase;
  padding-right: 40px;
}
.menu div {
  margin-left: 50px;
  width: auto;
}
.menu div:hover {
  cursor: pointer;
}
.menu a {
  text-decoration: none;
  color: #AA3D63;
}
@media only screen and (max-width: 600px) {
  .menu {
    flex-direction: column;
  }
  .menu div {
    margin-top: 20px;
  }
}
.intro {
  padding-top: 20px;
  align-self: center;
  margin-top: 50px;
  font-size: 30px;
  font-family: "Mulat Addis";
  color: #AA3D63;
  /* margin-left: auto; */
  /* margin-right: auto; */
 
}
.intro #appstore {
  height: 70px;
}
.intro #googleplay {
  height: 80px;
  margin: -4px;
}
.header {
  display: flex;
  position:fixed;
  width: 100%;
  border-bottom: 1px solid  #AA3D63;
  background-color: white;
}
.logoimage{
  height: 50px;
}
.logo {
  align-self: flex-start;
  padding-left: 50px;
}
.screenshots {
  background-color: #1fc8db;
  width: 100%;
}
.screenshots p {
  color: #FF5555;
  font-size:large;
  font-family: "Mulat Ahmed";
  
}
.howto {
  padding: 50px;
  background-color: #1fc8db;
}
.howto p {
  color: midnightblue;
  background-color: #1fc8db;
}
.howto img {
  height: 400px;
  width: auto;
}
.image {
  width: 207px;
  height: 448px;
  object-fit: fill;
  border-radius: 15% / 8%;
}
.promo {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 100%;
  padding-bottom: 30px;
  align-self: center;
  height: auto;
  background-color: #1fc8db;
  background-image: linear-gradient(10deg, #EADEDB 10%, white 50%, #EADEDB 75%);
}
.donatecontainer {
  display: flex;
  background-color: azure;
  flex-direction: column;
  font-family: "Mulat Abay";
  font-size:large;
}
.donatemessage {
  margin: 50px;
}
.social {
  margin: 30px;
}
.icon {
  height: 30px;
  width: 30px;
  margin: 50px;
}
p.title {
  padding-top: 20px;
  color:midnightblue;
  font-size: 24px;
  font-family: "Mulat Abay"
}

p.subtitle {

 align-self: center;
 text-align: center;
 color: black;
 font-family: "Mulat Ahmed";
 margin-top: -20px;
 font-size: 16px;
 font-style: italic;
}
p.bottomtitle {
  margin-left: auto;
  margin-right: auto;
  width: 225px;
}


.form {
  margin: 50px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: large;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color:midnightblue;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #1fc8db
}

.comment {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.footer {
  font-size: smaller;
  padding-top: 200px;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  background-color:#1fc8db;;
  width: 100%;
  padding-bottom: 200px;
}
.footer * {
  font-family: "Mulat Abay";
}
.share {
  margin-left: 50px;
}